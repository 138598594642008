



























































































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import validator from "validator";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },

  setup(_, { root }) {
    const { convertToSlug, goBack } = useMisc({ root });

    const model = reactive({
      name: "",
      url: "",
      alias: "",
      type: "anonymous",
      notificationAboutCompletion: false,
      notificationMail: "",
      aboveInfo: "",
      successInfo: "",
      status: true,
      participantsIds: [] as string[],
    });

    const state = reactive({
      valid: false,
      loading: false,
      error: false as unknown,
      success: false,
      participants: [],
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    //  Start fetch survey data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`survey/${root.$route.params.smid}`)
        .then(({ data }) => {
          model.name = data.name;
          model.url = data.url;
          model.alias = data.alias;
          model.type = data.type;
          model.notificationAboutCompletion = data.notificationAboutCompletion;
          model.notificationMail = data.notificationMail || undefined;
          model.aboveInfo = data.informationAboveSurvey || undefined;
          model.successInfo = data.informationAfterCompletion || undefined;
          model.status = data.isEnabled;
          model.participantsIds = data.participants.map((i: any) => i.id);
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    // End fetch survey data

    const fetchParticipants = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant`)
        .then(({ data: { participants } }) => {
          state.participants = participants
            .map((el: any) => ({
              id: el.id,
              name: `${el.degree} ${el.firstName} ${el.lastName} (${el.code})`,
              firstName: el.firstName,
              lastName: el.lastName,
              degree: el.degree,
              code: el.code,
              state: el.state,
            }))
            .filter((i: any) => i.state === "participant");
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.participants = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchParticipants);

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name,
        url: model.url,
        alias: model.alias,
        type: model.type,
        notificationAboutCompletion: model.notificationAboutCompletion,
        notificationMail: model.notificationAboutCompletion
          ? model.notificationMail
          : undefined,
        informationAboveSurvey: model.aboveInfo || undefined,
        informationAfterCompletion: model.successInfo || undefined,
        isEnabled: model.status,
        participantsIds: model.participantsIds || null,
      };

      state.loading = true;

      axiosInstance
        .put(`survey/${root.$route.params.smid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("event.panel.modules.surveyModule.editSuccess"),
          });
          setTimeout(() => {
            goBack("panel.event.view.surveyModule.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    watch(
      () => model.name,
      () => {
        model.alias = convertToSlug(model.name);
      }
    );

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      isRequiredUrl: [
        (v: string) =>
          (!!v && validator.isURL(v, { require_protocol: true })) ||
          root.$tc("layout.misc.validUrl"),
      ],
    };

    const selectAll = () => {
      model.participantsIds = state.participants.map((el: any) => el.id);
    };

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      rules,
      selectAll,
      goBack,
    };
  },
});
